require('./bootstrap');
require('./components/menu');

import SwiperCore, {Swiper, Navigation, Pagination, Autoplay, Lazy } from 'swiper/core';
import LazyLoad from 'vanilla-lazyload';
import initForm from './components/form';
import $ from "jquery";
import AOS from 'aos';
require('magnific-popup')

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy]);

document.addEventListener("DOMContentLoaded", function(event) {
    let media = window.matchMedia("screen and (min-width: 992px)");
    let timer;

    AOS.init();

    var lazyLoadInstance = new LazyLoad({
        // Your custom settings go here
    });

    const mpBannersSlider = new Swiper(".mp-banners.swiper-container", {
        loop: true,
        slidesPerView: 1,
        speed: 500,
        lazy: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },

        // If we need pagination
        pagination: {
            el: '.mp-banners .swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },
    })

    const partnersSlider = new Swiper(".partners .swiper-container", {
        loop: true,
        slidesPerView: 1,
        lazy: true,

        // If we need pagination
        pagination: {
            el: '.partners .swiper-pagination',
        },

        // Navigation arrows
        navigation: {
            nextEl: '.partners__swiper-button-next',
            prevEl: '.partners__swiper-button-prev',
        },

        // And if we need scrollbar
        scrollbar: {
            el: '.swiper-scrollbar',
        },

        breakpoints: {
            992: {
                slidesPerView: 6,
            },
        }
    })

    const arendaSkladaSlider = new Swiper(".arenda__slider", {
        loop: true,
        slidesPerView: 1,
        lazy: true,
        pagination: {
            el: '.arenda__slider .swiper-pagination',
        },
    });

    let emailFormBtnNodes = document.querySelectorAll('.email-form-btn');

    emailFormBtnNodes.forEach(function (el) {
        el.addEventListener('click', function () {
            $.magnificPopup.open({
                closeMarkup: "<button title=\"%title%\" type=\"button\" class=\"mfp-close\"></button>",
                items: {
                    type: 'inline',
                    src: "#email-form"
                }
            });
        })
    });

    initForm();
});
