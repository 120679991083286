document.addEventListener("DOMContentLoaded", function(event) {
    let mobileMenuBtnNode = document.querySelector('.mobile-menu__btn');
    let menuNode = document.querySelector(".header-mobile-menu");

    mobileMenuBtnNode.addEventListener('click', (e) => {
        if (mobileMenuBtnNode.classList.contains('active')) {
            mobileMenuBtnNode.classList.remove('active')
            menuNode.classList.remove('active')
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
            mobileMenuBtnNode.classList.add('active')
            menuNode.classList.add('active')
        }
    })
});
