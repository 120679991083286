import $ from "jquery";

let initForm = function (){
    let $forms = $('.form');

    $forms.submit(function (e) {
        e.preventDefault();
        let self = this;

        let nameInputNode = self.querySelector('[name="user-name"]').value;
        let phoneInputNode = self.querySelector('[name="user-phone"]').value;
        let tokenInputNode = self.querySelector('[name="_token"]').value;
        let data = {
            '_token': tokenInputNode,
            'user-name': nameInputNode,
            'user-phone': phoneInputNode,
        };

        $.ajax({
            method: self.method,
            url: self.action,
            type: 'json',
            data: data,
            error: function (e) {
                console.log(e);
            },
            success: function (data) {

                let successNode = self.parentNode.querySelector('.form__success');
                let errorsWrapNode = self.querySelector('.form__errors-wrap');
                let errorsNode = self.querySelector('.form__errors');

                if(data.success) {
                    self.parentNode.removeChild(self)
                    successNode.classList.add('visable')
                } else {
                    let messages = '';

                    errorsWrapNode.style.display = 'block';

                    for(var i in data.errors) {
                        let errors = data.errors[i];

                        messages += errors.join("<br>") + "<br>";
                    }
                    errorsNode.innerHTML = messages;
                }
            },
        })
    });
}

export default initForm ;

